<template>
  <footer
    class="md:flex justify-around py-12 bg-gray-50 font-worksans border-t"
  >
    <div class="hidden md:block">
      <h1 class="uppercase text-lg tracking-wider py-4 text-red-500">menu</h1>
      <ul class="space-y-1">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/jobs">Job search</router-link>
        </li>
        <!-- <li>
          <router-link to="/sap-driving">SAP Driving</router-link>
        </li> -->
        <li>
          <router-link to="/about">About</router-link>
        </li>
        <li>
          <router-link to="/employer-services">Employer services</router-link>
        </li>
        <li>
          <router-link to="/contact"> Contact</router-link>
        </li>
        <li>
          <router-link to="/privacy-policy"> Privacy policy</router-link>
        </li>
        <!-- <li>
          <router-link to="/sap-friendly-job-help"
            >Sap Friendly Job Help</router-link
          >
        </li> -->
      </ul>
    </div>
    <div class="text-center flex flex-col space-y-4">
      <img
        class="mx-auto"
        src="@/assets/ATG_Recruiting_Logo.png"
        style="height: 95px"
        alt="ATG Recruiting Job Search"
      />
      <a class="py-2" href="mailto:info@atgrecruiting.com"
        >info@atgrecruiting.com</a
      >
      <a href="tel:888-900-0484">888-900-0484</a>
      <!-- <p class="">888-900-0484</p> -->
    </div>
    <div class="flex flex-col items-center">
      <h1 class="uppercase text-lg tracking-wider py-4 text-red-500">
        socials
      </h1>
      <div class="flex gap-3">
        <a href="https://www.instagram.com/atgrecruiting1">
          <img class="h-7 w-7" src="@/assets/atg_ig.webp" alt="" />
        </a>
        <a href="https://www.facebook.com/atgrecruiting">
          <img class="h-7 w-7" src="@/assets/atg_fb.webp" alt="" />
        </a>
        <a href="https://www.youtube.com/@atgrecruiting">
          <img class="h-7 w-7" src="@/assets/atg_yt.webp" alt="" />
        </a>
        <a href="https://www.linkedin.com/company/atg-recruiting1">
          <img class="h-7 w-7" src="@/assets/atg_lkdn.webp" alt="" />
        </a>
      </div>
      <p class="py-2">&copy;{{ year }} ATG Recruiting Jobs</p>
    </div>
  </footer>
</template>

<script setup>
import { computed } from "vue";
const year = computed(() => new Date().getFullYear());
</script>
