<template>
  <header class="font-worksans bg-white shadow-sm">
    <nav class="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded">
      <div
        class="container flex flex-wrap items-center justify-between mx-auto"
      >
        <a href="/" class="flex items-center">
          <img
            src="@/assets/ATG_Recruiting_Logo.png"
            class="h-12 mr-3 sm:h-12"
            alt="Flowbite Logo"
          />
        </a>
        <button
          @click="toggleNavBar"
          data-collapse-toggle="navbar-default"
          type="button"
          class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg xl:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>

        <div
          :class="isOpen ? 'block' : 'hidden'"
          class="w-full xl:block xl:w-auto"
          id="navbar-default"
        >
          <ul
            class="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 xl:flex-row xl:space-x-8 xl:mt-0 xl:text-sm xl:font-medium xl:border-0 xl:bg-white"
          >
            <li @click="toggleNavBar">
              <router-link
                to="/"
                class="block py-2 pl-3 pr-4 rounded xl:bg-transparent xl:p-0"
                aria-current="page"
                >Home</router-link
              >
            </li>
            <li @click="toggleNavBar">
              <router-link
                to="/jobs"
                class="block py-2 pl-3 pr-4 rounded xl:bg-transparent xl:p-0"
                aria-current="page"
                >Job search</router-link
              >
            </li>
            <!-- <li @click="toggleNavBar">
              <router-link
                to="/sap-driving"
                class="block py-2 pl-3 pr-4 rounded xl:bg-transparent xl:p-0"
                aria-current="page"
                >SAP Driving</router-link
              >
            </li> -->
            <li @click="toggleNavBar">
              <router-link
                to="/about"
                class="block py-2 pl-3 pr-4 rounded xl:bg-transparent xl:p-0"
                aria-current="page"
                >About</router-link
              >
            </li>
            <li @click="toggleNavBar">
              <router-link
                to="/employer-services"
                class="block py-2 pl-3 pr-4 rounded xl:bg-transparent xl:p-0"
                aria-current="page"
                >Employer Services</router-link
              >
            </li>
            <li @click="toggleNavBar">
              <router-link
                to="/contact"
                class="block py-2 pl-3 pr-4 rounded xl:bg-transparent xl:p-0"
                aria-current="page"
                >Contact</router-link
              >
            </li>
            <li @click="toggleNavBar">
              <router-link
                to="/privacy-policy"
                class="block py-2 pl-3 pr-4 rounded xl:bg-transparent xl:p-0"
                aria-current="page"
                >Privacy policy</router-link
              >
            </li>
            <!-- <li @click="toggleNavBar">
              <router-link
                to="/sap-friendly-job-help"
                class="block py-2 pl-3 pr-4 rounded xl:bg-transparent xl:p-0"
                aria-current="page"
                >Sap Friendly Job Help</router-link
              >
            </li> -->

            <div class="flex xl:space-x-8">
              <li>
                <a
                  href="https://www.instagram.com/atgrecruiting1"
                  class="block py-2 pl-3 pr-4 rounded xl:bg-transparent xl:p-0"
                >
                  <img class="h-7 w-7" src="@/assets/atg_ig.webp" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/atgrecruiting"
                  class="block py-2 pl-3 pr-4 rounded xl:bg-transparent xl:p-0"
                >
                  <img class="h-7 w-7" src="@/assets/atg_fb.webp" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@atgrecruiting"
                  class="block py-2 pl-3 pr-4 rounded xl:bg-transparent xl:p-0"
                >
                  <img class="h-7 w-7" src="@/assets/atg_yt.webp" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/atg-recruiting1"
                  class="block py-2 pl-3 pr-4 rounded xl:bg-transparent xl:p-0"
                >
                  <img class="h-7 w-7" src="@/assets/atg_lkdn.webp" alt="" />
                </a>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </nav>

    <!-- <div class="flex justify-between items-center xl:px-20 py-4">
      <div class="flex-shrink-0">
        <img
          src="@/assets/ATG_Recruiting_Logo.png"
          style="height: 95px"
          alt="ATG Recruiting Job Search"
        />
      </div>
      <div class="">
        <nav class="hidden xl:flex list-none space-x-16">
          <li>
            <router-link to="/">Home</router-link>
          </li>
          <li>
            <router-link to="/jobs">Jobs</router-link>
          </li>
          <li>
            <router-link to="/about">About</router-link>
          </li>
          <li>
            <router-link to="/employer-services">Employer services</router-link>
          </li>
          <li>
            <router-link to="/contact"> Contact</router-link>
          </li>

          <div class="flex gap-3">
            <a href="https://www.instagram.com/atgrecruiting1">
              <img class="h-7 w-7" src="@/assets/atg_ig.webp" alt="" />
            </a>
            <a href="https://www.facebook.com/atgrecruiting">
              <img class="h-7 w-7" src="@/assets/atg_fb.webp" alt="" />
            </a>
            <a href="https://www.youtube.com/@atgrecruiting">
              <img class="h-7 w-7" src="@/assets/atg_yt.webp" alt="" />
            </a>
            <a href="https://www.linkedin.com/company/atg-recruiting1">
              <img class="h-7 w-7" src="@/assets/atg_lkdn.webp" alt="" />
            </a>
          </div>
        </nav>
      </div>
      <div class="sm:hidden w-full flex justify-end">
        <button @click="isOpen = !isOpen" class="block pt-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-9 w-9"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              v-if="!isOpen"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
            <path
              v-if="isOpen"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        

        <div
          v-if="isOpen"
          class="xl:hidden relative  w-full  pt-3"
        >
          <nav class="absolute top-24 list-none">
            <li>
              <router-link to="/">Home</router-link>
            </li>
            <li>
              <router-link to="/jobs">Jobs</router-link>
            </li>
            <li>
              <router-link to="/about">About</router-link>
            </li>
            <li>
              <router-link to="/employer-services"
                >Employer services</router-link
              >
            </li>
            <li>
              <router-link to="/contact"> Contact</router-link>
            </li>

            <div class="flex gap-3">
              <a href="https://www.instagram.com/atgrecruiting1">
                <img class="h-7 w-7" src="@/assets/atg_ig.webp" alt="" />
              </a>
              <a href="https://www.facebook.com/atgrecruiting">
                <img class="h-7 w-7" src="@/assets/atg_fb.webp" alt="" />
              </a>
              <a href="https://www.youtube.com/@atgrecruiting">
                <img class="h-7 w-7" src="@/assets/atg_yt.webp" alt="" />
              </a>
              <a href="https://www.linkedin.com/company/atg-recruiting1">
                <img class="h-7 w-7" src="@/assets/atg_lkdn.webp" alt="" />
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div> -->
  </header>
</template>

<script setup>
import { ref } from "vue";

let isOpen = ref(false);
function toggleNavBar() {
  isOpen.value = !isOpen.value;
}
</script>

<style lang="css" scoped></style>
