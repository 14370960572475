import JobSearchService from "@/services/JobSearch/JobSearchService.js";

const state = {
	jobSearchResults: []
};

const getters = {

};

const actions = {

	searchJobs({ commit }, key) {
		return JobSearchService.searchJob(key).then((response) => { 
			commit('SET_JOB_SEARCH_RESULTS', response.data)
		})
	}

};

const mutations = { 

	SET_JOB_SEARCH_RESULTS(state, results) {
		state.jobSearchResults = results
	}
	
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations   
}

