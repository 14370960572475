<template lang="">
  <div class="lg:flex justify-evenly items-center h-screen font-worksans">
    <div>
      <img src="@/assets/Truck_In_Parking_Lot.png" alt="">
    </div>
    <div class=" space-y-7">
      <h1 class="text-2xl md:text-5xl text-center  md:max-w-xl tracking-wide font-bold">
        Do you have a question or a suggestion ?
      </h1>
      <div class="flex md:flex-row flex-col items-center md:gap-4">
        <p class="text-gray-400">Let us know here</p>
        <div class="flex flex-col border-l p-3">
          <div class="flex items-center gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-gray-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
              />
            </svg>
            <a class="py-2 text-blue-600" href="mailto:info@atgrecruiting.com"
              >info@atgrecruiting.com</a
            >
          </div>

          <div class="flex items-center gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-gray-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
              />
            </svg>

            <a href="tel:888-900-0484">888-900-0484</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang=""></style>
