
import axios from "axios";

const Api = axios.create({
	baseURL: process.env.VUE_APP_BACKEND_APP_URL + "/api",
	withCredentials: false,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	} 
});

export default Api;