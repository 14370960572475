import JobTypeService from '@/services/JobType/JobTypeService.js';

const state = {
	jobTypes: []
};

const getters = {

}; 

const actions = {

	fetchJobTypes({ commit }) {
		JobTypeService.getJobTypes().then((response) => {
			commit('SET_JOB_TYPES', response.data)
		}).catch(() => {});
	}

}; 

const mutations = {

	SET_JOB_TYPES(state, jobTypes){
		state.jobTypes = jobTypes
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations 
}; 