import JobService from "@/services/Job/JobService.js";

const state = { 
	job: {}
};

const getters = {

};

const actions = { 
	getJob({ commit }, id) {
		return JobService.getJobById(id).then((response) => {
			commit('SET_JOB', response.data)
		})
	}
};

const mutations = { 
	SET_JOB(state, job) {
		state.job = job
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}