<template>
	<div class="font-bold font-inter">
    	<div class="h-screen flex items-center bg-gray-50">
	        <div class="w-full max-w-2xl mx-auto space-y-6 px-6 sm:px-0">
	            <p class="text-3xl md:text-5xl lg:text-7xl font-bold text-customBlue">
	                Are you lost?
	            </p>

	            <div class="border-t border-gray-600"></div>

	            <div class="w-full max-w-lg">
	                <p class="text-customBlue text-lg">It looks like you've wandered off into the abyss.</p>
	                <p class="text-sm text-customBlue font-medium">Come back home!</p>

	                <RouterLink :to="{ name: 'home'}" class="mt-4 rounded-md border border-white block w-full sm:w-64 py-2.5 font-medium text-center text-white bg-customBlue hover:bg-gray-500 transition">Back to Home</RouterLink>
	            </div>
	        </div>
    </div>
</div>
</template>

<script>
export default {

  name: 'PageNotFound',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>