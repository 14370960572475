import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Jobs from "@/views/Jobs.vue";
import SapDriving from '@/views/SapDriving.vue';
import About from "@/views/About.vue";
import EmployerServices from "@/views/EmployerServices.vue";
import Contact from "@/views/Contact.vue";
import SapFriendlyJobHelp from "@/views/SapFriendlyJobHelp.vue";
import SearchResults from "@/views/SearchResults.vue";
import JobDescription from "@/views/JobDescription.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import PageNotFound from "@/components/PageNotFound.vue";
import NProgress from 'nprogress';

import store from "@/store";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/jobs",
    name: "jobs",
    component: Jobs,
  },
  {
    path: "/sap-driving",
    name: "sap-driving",
    component: SapDriving,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/employer-services",
    name: "employer-services",
    component: EmployerServices,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    component: PrivacyPolicy,
  },
  {
    path: "/sap-friendly-job-help",
    name: "sap-friendly-job-help",
    component: SapFriendlyJobHelp,
  },
  {
    path: "/search-results",
    name: "searchResults",
    component: SearchResults,
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch("JobSearch/searchJobs", routeTo.query).then(() => {
        next()
      }).catch(() => { router.push({name: "home"}) })
    }
  },
  {
    path: "/jobs/:id",
    name: "jobDescription",
    component: JobDescription,
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch("Job/getJob", {"id":routeTo.params.id}).then(() => {
        next()
      }).catch(() => { router.push({name: "home"}) })
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "text-red-500",
  scrollBehavior() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
});

router.beforeEach((routeTo, routeFrom, next) => {
  NProgress.start()
  next()
})

router.afterEach(() => { NProgress.done() })

export default router;
