<template>
  <div class="font-worksans">
    <div class="relative bg-black">
      <img
        src="@/assets/sap_friendly_truck.webp"
        class="opacity-75"
        alt=""
        srcset=""
      />
      <div class="absolute top-1/2 w-full">
        <div class="flex flex-col justify-center items-center space-y-4">
          <h1 class="lg:text-7xl font-semibold uppercase text-white">
            Sap friendly job help
          </h1>
        </div>
      </div>
    </div>

    <!-- video link goes here -->

    <div class="pt-12">
      <div class="aspect-w-16 aspect-h-9 xl:aspect-h-5 xl:w-1/2 mx-auto">
        <iframe
          class="shadow-lg"
          scrolling="no"
          src="https://www.youtube.com/embed/lEjoOpEoPOs"
          title="New CDL A Driving Opportunity For SAP Drivers | Second Chance | ATGRecruiting"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>

    <!-- ZOHO-Form section -->
    <div class="py-12 flex justify-center text-center">
      <div class="space-y-8 w-full">
        <h2 class="text-4xl font-bold">Have a Clearing House Violation ?</h2>
        <hr class="border-red-600 w-1/2 mx-auto" />

        <div class="max-w-xl mx-auto">
          <p>
            We have helped many drivers with ClearingHouse violations get back
            to work. Fill out the form below completely and let's start working
            on getting you back in a truck.
          </p>
        </div>

        <div class="min-h-full lg:w-1/2 mx-auto">
          <iframe
            frameborder="0"
            style="height: 1850px; width: 99%; border: none"
            src="https://forms.zohopublic.com/atgrecruiting/form/SAPFriendlyJobHelpfromATGRecruiting1/formperma/fSMAIh6flTknhl8ke2fUIse4uIlyB2H7tIu-2Jtb9r8"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style></style>
