import { createStore } from "vuex";
import JobType from "@/store/JobType";
import JobSearch from "@/store/JobSearch";
import Job from "@/store/Job";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    JobType,
    JobSearch,
    Job
  },
});
