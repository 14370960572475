<template>
  <div class="relative sm:flex items-center p-4 sm:space-x-4">
    <div class="flex-1 flex sm:space-x-4">
      <div class="hidden sm:block flex-shrink-0">
        <!-- <img
          class="w-32 h-32 object-contain rounded border"
          :src="logo"
          :alt="job.company.name"
        /> -->
        <div
          class="flex items-center justify-center bg-blue-600 w-32 h-32 object-contain rounded border"
          :style="`background-color: ${job.job_type.color};`"
        >
          <span class="text-4xl text-white font-extrabold"> {{ job.job_type.slug }} </span>
        </div>
      </div>
      <div class="flex-1">
        <div class="flex space-x-2">
          <div class="sm:hidden">
            <div
              class="flex items-center justify-center bg-blue-600 w-12 h-12 object-contain rounded border"
              :style="`background-color: ${job.job_type.color};`"
            >
              <span class="text-lg text-white font-extrabold">
                {{ job.job_type.slug }}
              </span>
            </div>
          </div>
          <div>
            <RouterLink :to="{ name: 'jobDescription', params: {id: job.id}}" class="hover:underline">
              <h3 class="inline-block font-semibold text-lg">
                 {{ job.title }} 
              </h3>
            </RouterLink>
            <!-- <p class="text-xs text-gray-500 font-medium">
              revised on {{ revisedOn || createdAt }}
            </p> -->
          </div>
        </div>

        <p class="mt-2 text-gray-500 flex space-x-1">
          <span>$</span>
          <span
            ><span
              class="text-2xl font-bold text-gray-900"
              > {{ weeklyPay }} </span
            >
            / week</span
          >
        </p>

        <!-- <ul class="mt-4 text-sm" v-if="keyPoints.length"> -->
        <ul class="mt-4 text-sm">
          <li
            v-for="(keyPoint, index) in keyPoints"
            :key="`key_point_${index}`"
            class="flex space-x-2"
          >
            <div class="mt-0.5 flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="sm:mt-0 h-4 w-4 text-gray-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <p class="text-gray-700">{{ keyPoint }}</p>
          </li>
        </ul>

        <div class="mt-4 flex items-center space-x-4">
          <div class="flex space-x-2">
            <span
              class="text-xs font-medium px-2 py-1 bg-gray-100 border border-gray-200 rounded-md"
              > {{ job.job_type.name}} </span
            >
            <span
              class="text-xs font-medium uppercase px-2 py-1 bg-gray-100 border border-gray-200 rounded-md"
              >CDL  {{job.cdl_class}} </span
            >
            <span
              v-if="job.sap"
              class="text-xs font-medium uppercase px-2 py-1 bg-gray-100 border border-gray-200 rounded-md"
              >SAP</span
            >
          </div>
          <div class="sm:flex sm:space-x-4">
            <p class="flex items-center space-x-2">
              <span class="text-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <span
                class="text-sm text-gray-600 font-medium"
                > {{ job.company.name }} </span
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-end sm:justify-start mt-4 sm:mt-0">
      <div class="w-full sm:w-auto flex flex-shrink-0 sm:pr-4 space-x-2">
        <RouterLink
          :to="{ name: 'jobDescription', params: {id: job.id}}"
          class="w-full block text-center px-4 py-3 sm:py-2 text-white text-sm font-medium rounded-md bg-customBlue hover:bg-gray-500 shadow transition"
          >More Info</RouterLink>
        
      </div>
    </div>

  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  props:{
    job: Object
  },

  data () {
    return {

    }
  },

  computed:{
    createdAt() {
      return DateTime.fromISO(this.job.created_at).toLocaleString(
        DateTime.DATE_MED
      );
    },

    revisedOn() {
      if (!this.job.revised_on) return null;

      return DateTime.fromISO(this.job.revised_on).toLocaleString(
        DateTime.DATE_MED
      );
    },

    weeklyPay() {
      return this.job?.average_weekly_pay
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    keyPoints() {
      return Array.from(Array(5).keys())
        .map((key) => this.job[`key_point_${key + 1}`])
        .filter(Boolean);
    },

  } 
};
</script>
