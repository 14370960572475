<template>
  <div class="font-worksans">
    <div class="relative bg-black">
      <img
        src="@/assets/sapDrivingTruck.webp"
        class="opacity-75"
        alt=""
        srcset=""
      />
      <div class="absolute top-1/2 w-full">
        <div class="flex flex-col justify-center items-center space-y-4">
          <h1 class="text-7xl font-bold text-white">SAP Driving Process</h1>
          <button
            class="px-2 py-1 uppercase text-lg text-white font-extrabold tracking-wider bg-[#123456] border border-opacity-50 border-white rounded-full hover:bg-red-500"
          >
            clearing house violations
          </button>
        </div>
      </div>
    </div>

    <!-- Getting Back on The Road -->

    <div class="grid grid-cols-12 lg:place-items-center py-12">
      <div class="col-span-12 lg:col-span-6">
        <img src="@/assets/sapDriver.webp" class="h-[500px] w-auto object-contain" alt="" />
      </div>
      <div class="px-12 col-span-12 lg:col-span-6 space-y-7">
        <h1 class="text-4xl font-bold tracking-wide text-red-500">
          Getting back on The Road
        </h1>
        <hr class="w-1/2 border-red-500" />
        <p class="lg:max-w-lg text-lg text-justify leading-relaxed">
          Your career is not over, we talk to drivers every day that have lost
          all hope. Do not despair, we are here to help you all we can. To get
          you back in a truck, you will have some work to do. It all starts with
          finding the counselor to help you get ready for your return to duty.
        </p>
      </div>
    </div>

    <!-- Finding a Counselor -->

    <div class="grid grid-cols-12 place-items-center py-12">
      <div class="px-12 col-span-12 lg:col-span-6 space-y-7">
        <h1 class="text-4xl font-bold tracking-wide text-red-500">
          Finding a Counselor
        </h1>
        <hr class="w-1/2 border-red-500" />
        <p class="lg:max-w-lg text-lg text-justify leading-relaxed">
          There are a ton of counselors that you can find online that can help
          you through the process. After talking to hundreds of drivers, we
          HIGHLY recommend Charles Erby. He is what I would describe as a
          calming soul, which you may need to get you through this sometimes
          trying process. He can help you no matter where you are located. Visit
          his website at
          <a href="www.Focus7.us" class="underline text-blue-500"
            >www.Focus7.us</a
          >
          When you speak with Charles tell him ATG Recruiting sent you.
        </p>
      </div>
      <div class="col-span-12 lg:col-span-6 row-start-1 lg:row-start-auto">
        <img src="@/assets/counselor.webp" class="h-[500px] w-auto object-contain" alt="" />
      </div>
    </div>

    <!-- Finding a SAP Friendly Job -->

    <div class="grid grid-cols-12 place-items-center py-12">
      <div class="col-span-12 lg:col-span-6">
        <img src="@/assets/jobSearch.png" class="h-[500px] w-auto object-contain" alt="" />
      </div>
      <div class="px-12 col-span-12 lg:col-span-6 space-y-7">
        <h1 class="text-4xl font-bold tracking-wide text-red-500">
          Finding a SAP Friendly Job
        </h1>
        <hr class="w-1/2 border-red-500" />
        <p class="lg:max-w-lg text-lg text-justify leading-relaxed">
          Once you have completed your SAP sessions and are cleared for your
          Return To Duty (RTD) test check out our database of open CDL jobs. All
          it takes is your zip code.
        </p>
        <div class="">
          <router-link
            to="/jobs"
            class="bg-[#123456] px-4 py-2 rounded-full text-white uppercase tracking-wide hover:bg-red-500"
          >
            Search sap jobs
          </router-link>
        </div>
      </div>
    </div>

    <!-- Helpful Links -->

    <div class="grid grid-cols-12 place-items-center py-12">
      <div class="px-12 col-span-12 lg:col-span-6 space-y-7">
        <h1 class="text-4xl font-bold tracking-wide text-red-500">
          Helpful Links
        </h1>
        <hr class="w-1/2 border-red-500" />
        <div class="space-y-2">
          <p class="max-w-lg text-lg">
            Clearing House Help Line -
            <a href="tel:844-955-0207" class="underline text-blue-500"
              >844-955-0207</a
            >
          </p>
          <p class="max-w-lg text-lg">
            Clearing House Compliance -
            <a href="tel:800-225-3784" class="underline text-blue-500"
              >800-225-3784</a
            >
          </p>
          <p class="max-w-lg text-lg">
            <span class="font-extrabold text-xl">F</span>ederal
            <span class="font-extrabold text-xl">M</span>otor
            <span class="font-extrabold text-xl">C</span>arrier
            <span class="font-extrabold text-xl">S</span>afety
            <span class="font-extrabold text-xl">A</span>dministration (FMCSA)
            <a
              href="https://clearinghouse.fmcsa.dot.gov/"
              class="underline text-blue-500"
            >
              https://clearinghouse.fmcsa.dot.gov/</a
            >
          </p>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-6 row-start-1 lg:row-start-auto">
        <img src="@/assets/helpfulLinks.webp" class="h-[500px] w-auto object-contain" alt="" />
      </div>
    </div>
  </div>
</template>

<style></style>
