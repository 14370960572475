<template>
	<div
    class="max-w-7xl mx-auto my-8 bg-gray-50 rounded shadow divide-y transition-colors"
  >
  <template v-if="jobSearchResults.length">
    
      <JobCard
        v-for="job in jobSearchResults"
        :job="job"
        :key="job.id"
        class="hover:bg-gray-50 transition"
      />

  </template>
    <!-- if job not found show -->
    
    <div v-else class="text-center py-12 space-y-4">
      <p class="font-medium text-sm text-gray-700">
        We couldn't find any jobs.
      </p>
    </div>

  </div>
</template>

<script>
import { useStore } from "vuex";
import JobCard from "@/components/Jobs/JobCard";
import { computed } from 'vue';

export default {
  components:{
    JobCard
  },

  setup() {
    const store = useStore();

    const jobSearchResults = computed(() => store.state.JobSearch.jobSearchResults);

    return {
      jobSearchResults
    }  
  }
    
}
</script>

<style lang="css" scoped>
</style>