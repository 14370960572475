<template lang="">
  <div>
    <h1
      class="font-worksans tracking-wider text-3xl text-center font-bold py-12 md:pt-12"
    >
      Employer Services
    </h1>
  </div>
  <div class="md:p-32 grid grid-cols-12 gap-4 text-center font-worksans">
    <div
      class="col-span-12 md:col-span-4 space-y-4 flex flex-col items-center justify-between border"
    >
      <div class="w-full h-full">
        <img src="@/assets/Man_Folding_Hands.png" class="w-full" alt="" />
      </div>

      <p class="text-2xl">Full Service Recruiting</p>
      <hr class="w-1/2" />
      <p>1 hr</p>
      <div class="pb-4">
        <button
          class="uppercase text-sm bg-red-500 rounded-full px-6 py-2 text-white tracking-widest"
        >
          <a href="tel:888-900-0484"> book now </a>
        </button>
      </div>
    </div>

    <div
      class="col-span-12 md:col-span-4 space-y-4 flex flex-col items-center justify-between border-t border-b"
    >
      <div class="w-full h-full">
        <img
          src="@/assets/Woman_Reading_Application.png"
          class="w-full"
          alt=""
        />
      </div>

      <p class="text-2xl">Pre Qualified Resumes</p>
      <hr class="w-1/2" />
      <p>1 hr</p>
      <div class="pb-4">
        <button
          class="uppercase text-sm bg-red-500 rounded-full px-6 py-2 text-white tracking-widest"
        >
          <a href="tel:888-900-0484"> book now </a>
        </button>
      </div>
    </div>

    <div
      class="col-span-12 md:col-span-4 space-y-4 flex flex-col items-center justify-between border"
    >
      <div class="w-full h-full">
        <img src="@/assets/Coffee_and_Paper.png" class="w-full" alt="" />
      </div>

      <p class="text-2xl">Job Advertising Service</p>
      <hr class="w-1/2" />
      <p>1 hr</p>
      <div class="pb-4">
        <button
          class="uppercase text-sm bg-red-500 rounded-full px-6 py-2 text-white tracking-widest"
        >
          <a href="tel:888-900-0484"> book now </a>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang=""></style>
