<template>
  <div class="home">
    <div class="py-24 flex flex-col">
      <div class="px-20 py-2">
        <a href="tel:888-900-0484" class="float-right">
          Call Now 888-900-0484
        </a>
      </div>
      <div class="flex flex-1 items-center justify-center">
        <div class="relative w-full max-w-md">
          <div class="relative bg-gray-50 border p-8 shadow-lg rounded-md">
            <div class="flex justify-center">
              <img
                src="@/assets/ATG_Recruiting_Logo.png"
                alt="ATG Recruiting Logo"
                class="w-48"
              />
            </div>

            <div class="flex justify-center py-3">
              <h1 class="text-2xl">Job Search</h1>
            </div>

            <form @submit.prevent="submitForm">
              <div class="mt-4">
                <label for="zipcode" class="sr-only">Zip Code</label>
                <input
                  type="text"
                  name="zipcode"
                  id="zipcode"
                  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md"
                  v-model="state.searchForm.zipcode"
                  @blur="v$.searchForm.zipcode.$touch()"
                  placeholder="Enter Your Zip Code"
                />
                <p
                  v-if="v$.searchForm.zipcode.$error"
                  class="flex flex-col mt-1 ml-2 text-sm text-red-600"
                >
                  <span v-if="v$.searchForm.zipcode.required.$invalid"
                    >You need to provide us a zip code.</span
                  >
                  <span v-if="v$.searchForm.zipcode.numeric.$invalid"
                    >That doesn't look like a valid zip code.</span
                  >
                </p>
              </div>

              <div class="mt-4">
                <div class="grid grid-cols-2 gap-4">
                  <div>
                    <label
                      for="cdl"
                      class="block text-sm font-medium text-gray-700"
                      >CDL Class</label
                    >
                    <select
                      id="cdl"
                      name="cdl"
                      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      @change="state.searchForm.cdl_class = $event.target.value"
                    >
                      <option
                        v-for="cdl_class in state.cdl_classes"
                        :key="cdl_class.value"
                        :value="cdl_class.value"
                        :selected="
                          cdl_class.value === state.searchForm.cdl_class
                        "
                      >
                        {{ cdl_class.label }}
                      </option>
                    </select>
                  </div>
                  <div>
                    <label
                      for="job_type"
                      class="block text-sm font-medium text-gray-700"
                      >Job Type</label
                    >
                    <select
                      id="job_type"
                      name="job_type"
                      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      @change="state.searchForm.job_type = $event.target.value"
                    >
                      <option
                        :selected="state.searchForm.job_type === 'all'"
                        value="all"
                      >
                        All
                      </option>
                      <option
                        v-for="jobType in jobTypes"
                        :value="jobType.id"
                        :key="`job-type-${jobType.id}`"
                        :selected="state.searchForm.job_type === jobType.id"
                      >
                        {{ jobType.name }}
                      </option>
                    </select>
                  </div>
                  <!-- <div class="relative flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="sap"
                        type="checkbox"
                        class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                        name="sap"
                        value="1"
                        v-model="state.searchForm.sap_only"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="sap" class="text-sm font-medium text-gray-700"
                        >SAP Jobs Only</label
                      >
                    </div>
                  </div> -->
                </div>
              </div>

              <div class="mt-8">
                <button
                  class="flex items-center justify-center w-full py-3 bg-customBlue hover:bg-gray-500 text-white text-sm font-medium rounded disabled:bg-blue-300 disabled:cursor-not-allowed"
                  :disabled="state.formProcessing"
                >
                  <svg
                    v-show="state.formProcessing"
                    class="animate-spin mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Get Jobs</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import { reactive, computed, watch } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    store.dispatch("JobType/fetchJobTypes");

    const jobTypes = computed(() => store.state.JobType.jobTypes);

    const state = reactive({
      cdl_classes: [
        { value: "a", label: "CDL A" },
        { value: "b", label: "CDL B" },
      ],

      formProcessing: false,

      searchForm: createFreshSearchObject(),
    });

    const rules = computed(() => {
      return {
        searchForm: {
          zipcode: { required, numeric },
        },
      };
    });

    const v$ = useVuelidate(rules, state);

    function createFreshSearchObject() {
      return {
        zipcode: "",
        cdl_class: "a",
        job_type: "all",
        sap_only: 0,
      };
    }

    watch(
      () => state.searchForm.sap_only,
      (newValue) => {
        if (newValue) {
          state.searchForm.sap_only = 1;
        }
      }
    );

    function submitForm() {
      state.formProcessing = true;
      this.v$.$validate();
      if (!this.v$.$error) {
        router.push({ name: "searchResults", query: { ...state.searchForm } });
      } else {
        state.formProcessing = false;
      }
    }

    return {
      submitForm,
      jobTypes,
      state,
      v$,
    };
  },
};
</script>
