<template>
  <div class="mt-4 sm:mt-8">
    <div class="max-w-7xl mx-auto">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-8">
          <p
            v-if="job.job.sap"
            class="sm:mx-3 md:mx-0 mb-2 sm:mb-4 py-2 px-4 sm:rounded-md shadow border-t border-b sm:border-l sm:border-r border-green-600 bg-green-100 text-green-900 text-sm font-semibold"
          >
            SAP drivers encouraged to apply!
          </p>

          <div class="p-4 sm:p-6 bg-gray-50 border shadow sm:rounded-md">
            <!-- <p class="text-xs text-gray-500 font-medium">
              revised on {{ revisedOn || createdAt }}
            </p> -->
            <h3 class="mt-1 text-lg leading-6 font-medium text-gray-900">
              {{ job.job.title }}
            </h3>

            <!-- Revisit this section -->
            <p
              v-if="job.job.hiring_area_type === 'proximity'"
              class="mt-1 text-sm text-gray-500 font-medium"
            >
              Hiring within {{ job.job.hiring_area_within }} miles of
              {{ job.job.hiring_area_zip }}
            </p>
            <p
              v-if="job.job.hiring_area_type === 'state'"
              class="mt-1 text-sm text-gray-500 font-medium"
            >
              Hiring within the state of {{ job.job.hiring_area_state }}
            </p>

            <div
              v-if="video"
              class="mt-4 relative rounded-md overflow-hidden"
              style="padding-top: 56.25%"
            >
              <iframe
                class="absolute inset-0 w-full h-full"
                :src="`https://www.youtube.com/embed/${video}`"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>

            <div class="mt-4 border-t"></div>

            <div class="mt-4">
              <p class="flex items-center space-x-2">
                <span class="text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span class="text-sm text-gray-600 font-medium"
                  >{{ job.job.company.name }}
                </span>
                <!-- <span v-if="isAgent" class="text-sm">
                  {{ job.label ? ` - ${job.label}` : "" }}
                </span> -->
              </p>

              <p class="">
                <span class="text-2xl font-medium"> {{ weeklyPay }} </span>
                / week
              </p>
              <p class="mt-2 flex items-center space-x-2">
                <span
                  class="px-4 py-1 rounded-md bg-blue-600 text-white text-sm font-medium uppercase"
                  >CDL {{ job.job.cdl_class }}
                </span>
                <span
                  class="px-4 py-1 rounded-md bg-blue-600 text-white text-sm font-medium"
                >
                  {{ job.job.job_type.name }}
                </span>
                <span
                  class="px-4 py-1 rounded-md bg-blue-600 text-white text-sm font-medium"
                  >CPM {{ job.job.cost_per_mile }}
                </span>
              </p>
            </div>

            <div class="mt-4">
              <div class="grid grid-cols-2 md:rid-cols-3 gap-4">
                <p class="flex items-center space-x-2">
                  <span class="text-blue-600">
                    <TruckIcon class="w-5" />
                  </span>
                  <span class="text-sm font-medium text-gray-600"
                    >{{
                      job.job.truck == "0"
                        ? "Take Truck Home"
                        : "Leave Truck at DC"
                    }}
                  </span>
                </p>
                <p class="flex items-center space-x-2">
                  <span class="text-blue-600">
                    <CheckCircleIcon class="h-5 w-5" />
                  </span>
                  <span
                    v-if="job.job.touch_freight"
                    class="text-sm font-medium text-gray-600"
                    >Driver Unloads Freight</span
                  >
                  <span v-else class="text-sm font-medium text-gray-600"
                    >No Touch Freight</span
                  >
                </p>
                <p class="flex items-center space-x-2">
                  <span class="text-blue-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      class="w-5"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm92.49 313l-20 25a16 16 0 01-22.49 2.5l-67-49.72a40 40 0 01-15-31.23V112a16 16 0 0116-16h32a16 16 0 0116 16v144l58 42.5a16 16 0 012.49 22.5z"
                      />
                    </svg>
                  </span>
                  <span class="text-sm font-medium text-gray-600">
                    {{ job.job.reset_hours }} hours Reset</span
                  >
                </p>
                <p class="flex items-center space-x-2">
                  <span v-if="job.job.pets_allowed" class="text-blue-600">
                    <CheckCircleIcon class="h-5 w-5" />
                  </span>
                  <span v-else class="text-red-500">
                    <XCircleIcon class="h-5 w-5" />
                  </span>
                  <span class="text-sm font-medium text-gray-600"
                    >Pets Allowed</span
                  >
                </p>
                <p class="flex items-center space-x-2">
                  <span class="text-blue-600">
                    <FlaskIcon class="h-5 w-5" />
                  </span>
                  <span
                    v-if="job.job.drug_test === 'hair'"
                    class="text-sm font-medium text-gray-600"
                    >Drug Test: Hair</span
                  >
                  <span
                    v-if="job.job.drug_test === 'urine'"
                    class="text-sm font-medium text-gray-600"
                    >Drug Test: Urine</span
                  >
                </p>
                <p class="flex items-center space-x-2">
                  <span v-if="job.job.riders_allowed" class="text-blue-600">
                    <CheckCircleIcon class="h-5 w-5" />
                  </span>
                  <span v-else class="text-red-500">
                    <XCircleIcon class="h-5 w-5" />
                  </span>
                  <span class="text-sm font-medium text-gray-600"
                    >Riders Allowed</span
                  >
                </p>
              </div>
            </div>

            <div class="mt-8 space-y-2">
              <div class="text-sm" v-html="job.job.description"></div>
            </div>
          </div>
        </div>

        <div class="col-span-12 lg:col-span-4">
          <div>
            <div>
              <div class="space-y-4">
                <div class="px-4 py-4 bg-gray-50 border shadow sm:rounded-md">
                  <div class="flex flex-col items-center space-y-2">
                    <a
                      ref="main-call-button"
                      target="_blank"
                      :href="`tel:${job.job.company.phone_number}`"
                      class="flex justify-center items-center w-full sm:w-64 lg:w-full space-x-2 bg-blue-500 text-white text-sm font-medium px-4 py-3 sm:py-2 rounded-md shadow hover:bg-blue-600 transition"
                      id="main-apply-button"
                    >
                      <span>Call Now</span>
                      <span><PhoneIcon class="h-5 w-5" /></span>
                    </a>

                    <a
                      ref="main-apply-button"
                      target="_blank"
                      :href="job.job.job_application_url"
                      class="flex justify-center items-center w-full sm:w-64 lg:w-full space-x-2 bg-blue-600 text-white text-sm font-medium px-4 py-3 sm:py-2 rounded-md shadow hover:bg-blue-700 transition"
                    >
                      <span>Apply Now</span>
                      <span>
                        <ExternalLinkIcon class="h-5 w-5" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="mt-4 px-4 py-4 bg-gray-50 border shadow sm:rounded-md"
              >
                <h3 class="text-sm font-medium">Share Job</h3>
                <div class="mt-2 space-x-2">
                  <a
                    class="inline-flex p-2 rounded-md hover:shadow"
                    :href="`https://www.facebook.com/sharer/sharer.php?u=${shareableUrl}`"
                    target="_blank"
                    rel="noopener"
                  >
                    <FacebookIcon class="inline-block h-6 w-6" />
                  </a>
                  <button
                    @click="copyShareableUrl"
                    type="button"
                    class="inline-flex p-2 rounded-md hover:shadow"
                  >
                    <ShareIcon class="inline-block h-6 w-6" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <template v-if="!isAgent"> -->
  <div>
    <a
      ref="mobile-apply-button"
      class="sm:invisible fixed bottom-8 right-8 px-6 py-3 flex items-center justify-center space-x-2 bg-blue-600 text-sm font-medium text-white hover:bg-blue-700 rounded-full shadow transition"
      :href="job.job.job_application_url"
      target="_blank"
    >
      <span>Apply Now</span>
      <ExternalLinkIcon class="h-5 w-5" />
    </a>
    <a
      ref="mobile-call-button"
      class="sm:hidden sm:invisible fixed right-8 mb-2 px-6 py-3 flex items-center justify-center space-x-2 bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 rounded-full shadow transition"
      :class="job.job.job_application_url ? 'bottom-20' : 'bottom-8'"
      :href="`tel:${job.job.company.phone_number}`"
      v-show="job.job.company.phone_number"
    >
      <span>Call Now</span>
      <PhoneIcon class="h-5 w-5" />
    </a>
    <a
      ref="mobile-call-button"
      class="sm:hidden sm:invisible fixed right-8 mb-2 px-6 py-3 flex items-center justify-center space-x-2 bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 rounded-full shadow transition"
      href="1"
    >
      <span>Call Now</span>
      <PhoneIcon class="h-5 w-5" />
    </a>
  </div>

  <transition
    enter-from-class="transform translate-y-full opacity-0"
    leave-to-class="transform translate-y-full opacity-0"
    leave-from-class="transform translate-y-0 opacity-100"
    enter-to-class="transform translate-y-0 opacity-100"
    enter-active-class="transition duration-150"
    leave-active-class="transition duration-100"
  >
    <div
      v-if="state.copied"
      class="fixed bottom-4 inset-x-6 max-w-sm mx-auto bg-gray-50 border bg-opacity-90 rounded px-4 py-3 text-center font-medium text-sm border border-gray-300"
    >
      Link copied to clipboard!
    </div>
  </transition>
</template>

<script>
import { useStore } from "vuex";
import { reactive, computed } from "vue";
import { DateTime } from "luxon";
import { ShareIcon } from "@heroicons/vue/outline";
import {
  CheckCircleIcon,
  ExternalLinkIcon,
  PhoneIcon,
  XCircleIcon,
} from "@heroicons/vue/solid";

import { FacebookIcon, FlaskIcon, TruckIcon } from "@/components/Icons";

export default {
  name: "JobDescription",

  components: {
    CheckCircleIcon,
    ExternalLinkIcon,
    PhoneIcon,
    XCircleIcon,
    FacebookIcon,
    FlaskIcon,
    TruckIcon,
    ShareIcon,
  },

  setup() {
    const store = useStore();

    const state = reactive({
      copied: false,
    });

    const job = computed(() => store.state.Job.job);

    function copyShareableUrl() {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          state.copied = true;
          setTimeout(() => (state.copied = false), 3000);
        })
        .catch(() => {});
    }

    return {
      job,
      state,
      copyShareableUrl,
    };
  },

  computed: {
    createdAt() {
      return DateTime.fromISO(this.job.job.created_at).toLocaleString(
        DateTime.DATE_MED
      );
    },

    revisedOn() {
      if (!this.job.job.revised_on) return null;

      return DateTime.fromISO(this.job.job.revised_on).toLocaleString(
        DateTime.DATE_MED
      );
    },

    weeklyPay() {
      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(this.job.job.average_weekly_pay);
    },

    video() {
      const url = this.job.job.video_url;

      if (!url) {
        return null;
      }

      if (url.includes("?v=")) {
        return url.split("?v=").at(-1);
      } else if (url.includes("youtu.be/")) {
        return url.split("youtu.be/").at(-1);
      }

      return null;
    },

    shareableUrl() {
      return window.location.href;
    },
  },
};
</script>

<style lang="css" scoped></style>
